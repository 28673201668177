/* => Breakpoints
/* ------------------------------------------------------------------------------ */

$screen-s: 640px;
$screen-m: 768px;
$screen-l: 1024px;
$screen-xl: 1280px;
$screen-2xl: 1536px;

@media print {
  $screen-s: 550px;
  $screen-m: 600px;
  $screen-l: 900px;
  $screen-xl: 1220px;
}

/* => Colors
/* ------------------------------------------------------------------------------ */
$brand: #c20736 !default;
$brand-warm-1: #f2efed !default;
$brand-warm-2: #e5e0da !default;
$brand-warm-3: #d4cbc2 !default;
$brand-warm-4: #c3b6a9 !default;
$brand-warm-5: #a99784 !default;
$brand-warm-6: #988877 !default;
$brand-warm-7: #756a5c !default;
$brand-cool-1: #edeff0 !default;
$brand-cool-2: #dadfe1 !default;
$brand-cool-3: #c2cacd !default;
$brand-cool-4: #a9b5b9 !default;
$brand-cool-5: #84959b !default;
$brand-cool-6: #6d7d82 !default;
$brand-cool-7: #5a686c !default;
$brand-green-1: #f7f6ec !default;
$brand-green-2: #eeeed8 !default;
$brand-green-3: #e3e2be !default;
$brand-green-4: #d8d6a4 !default;
$brand-green-5: #c7c57d !default;
$brand-green-6: #aeae6e !default;
$brand-cool-green-1: #f4f5f2 !default;
$brand-cool-green-2: #e8ebe5 !default;
$brand-cool-green-3: #d9ddd3 !default;
$brand-cool-green-4: #cacfc2 !default;
$brand-cool-green-5: #9ea696 !default;
$brand-cool-green-6: #8e9687 !default;
$snow: #fff !default;
$black: #000 !default;
$deep-blue: #000028 !default;
$deep-blue-60: #62627A !default;
$deep-blue-2: #2B2F43 !default;
$deep-blue-3: #7B89A1 !default;
$deep-blue-4: #A3ACBD !default;
$teal-light: #3CC8BE !default;
$stone-extralight: #becdd7 !default;
$stone-light: #7B89A1 !default;
$yellow-light: #FFB900 !default;
$orange: #D4691B !default;
$stone-dark: #3C464B !default;
$stone-dark-15: #E2E3E4 !default;
$stone-dark-75: #6D7478 !default;

$body-text: $snow !default;

$border-gray: #eee !default;

/* => Transition
/* ------------------------------------------------------------------------------ */
$default-transition: all 0.150s ease-in-out;

/* => Icons
/* ------------------------------------------------------------------------------ */

$icon-chevron-down: url("data:image/svg+xml,%3Csvg fill='none' height='9' viewBox='0 0 12 9' width='12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m6 8.13281-6-6 1.26562-1.265622 4.73438 4.101562 4.7344-4.101562 1.2656 1.265622z' fill='%23c3b6a9'/%3E%3C/svg%3E");

$icon-chevron-right: url("data:image/svg+xml,%3Csvg width='14' height='21' viewBox='0 0 14 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.0547 10.501L3.05469 20.501L0.945312 18.3916L7.78125 10.501L0.945312 2.61035L3.05469 0.500977L13.0547 10.501Z' fill='%23c20736'/%3E%3C/svg%3E%0A");

$icon-chevron-left: url("data:image/svg+xml,%3Csvg width='14' height='21' viewBox='0 0 14 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.945312 10.501L10.9453 0.500977L13.0547 2.61035L6.21875 10.501L13.0547 18.3916L10.9453 20.501L0.945312 10.501Z' fill='%23c20736'/%3E%3C/svg%3E%0A");

$icon-close: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.4062 12L6 8.1875L1.59375 12L0 10.4062L3.8125 6L0 1.59375L1.59375 0L6 3.8125L10.4062 0L12 1.59375L8.1875 6L12 10.4062L10.4062 12Z' fill='%23c3b6a9'/%3E%3C/svg%3E%0A");
