@import "@/assets/scss/abstracts/_global.scss";
.c-step {
  padding-bottom: 120px;
  position: relative;

  &__head {
    display: flex;

    .c-step__heading {
      margin-top: 18px;
    }
  }

  &__counter {
    @include t6;
    color: $snow;
    padding: 24px 32px;
    background: $stone-light;
    margin-right: 24px;
  }

  &__heading {
    margin-bottom: 1rem;
  }

  &__description {
    color: $black;
    margin-bottom: 24px;

    &--hint {
      @include s2;
      margin-bottom: 40px;
      display: flex;
      align-items: center;
    }
  }

  &__basics {
    display: flex;
    gap: 8px;
    margin-bottom: 80px;

    &--alternatives {
      margin-bottom: 40px;
    }
  }

  &__button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  &__addedProduct-badge {
    background-color: $brand;
    padding: 10px 20px 10px 10px;
    margin-right: 10px;

    .c-icon {
      vertical-align: middle;
      transform: translateY(-2px);
    }
  }

  &__result {
    &-features {
      h3 {
        color: $black;
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 24px;
      }
    }
  }

  @include mq($screen-m, max) {
    padding-bottom: 80px;
    &__heading {
      margin-bottom: 16px;
    }
    &__basics {
      margin-bottom: 40px;
      flex-wrap: wrap;
    }
    &__head {
      &--step {
        margin-bottom: 40px;

        .c-alternatives__heading {
          margin-top: 0;
        }
      }
    }
    &__button-wrapper {
      margin-top: 40px;
    }
    &__counter {
      padding: 16px;
    }
    &__description {
      margin-bottom: 16px;

      &--hint {
        margin-bottom: 24px;
      }
    }
  }
  @include mq($screen-s, max) {
    &__basics {
      margin-bottom: 40px;
    }
  }
}

.c-results__result {
  padding-bottom: 40px;

  &:not(:last-child) {
    margin-bottom: 80px;
  }

  &:last-child {
    margin-bottom: 50px;
  }
}

.c-result {
  padding-bottom: 40px;
  margin-bottom: 50px;

  &__feature-description {
    color: $black;
    margin-bottom: 40px;
  }

  &__product {
    margin-bottom: 56px;

    &-bundle {
      border: 4px solid $brand;
      padding: 40px;
      position: relative;

      &::before {
        content: "Bundle";
        position: absolute;
        top: -15px;
        left: 40px;
        background-color: $brand;
        border-left: 4px solid $snow;
        border-right: 4px solid $snow;
        padding-left: 16px;
        padding-right: 16px;
        color: $snow;
        line-height: 28px;
      }

      &:lang(en) {
        &::before {
          content: "Bundle";
        }
      }

      &:lang(dk) {
        &::before {
          content: "Pakkeforslag";
        }
      }

      &:lang(fi) {
        &::before {
          content: "Pakettiehdotukset";
        }
      }

      &:lang(no) {
        &::before {
          content: "Pakkeforslag";
        }
      }

      &:lang(se) {
        &::before {
          content: "Paketförslag";
        }
      }
    }

    &-bundle {
      &__products {
        display: flex;
        align-items: center;
        align-items: stretch;
      }
    }

    &-bundle-plus {
      color: $brand;
      margin-left: 20px;
      margin-right: 20px;
      min-width: 40px;
      align-self: center;
    }
  }

  .c-step__child-step {
    border: 4px solid $brand;
    margin-top: 16px;
    padding: 16px;
    margin-bottom: 16px;
  }
}

.c-alternatives {
  &__section {
    padding-bottom: 40px;
    border-bottom: 4px solid $brand;

    &:not(:last-child) {
      margin-bottom: 80px;
    }

    &:last-child {
      margin-bottom: 50px;
    }
  }

  &__extras-heading {
    @include t5;
    color: $black;
    margin-bottom: 24px;
  }

  &__main-feature {
    margin-bottom: 8px;
  }
}

.c-features {
  &__head {
    display: flex;
    margin-bottom: 56px;
  }

  padding-bottom: 40px;
  margin-bottom: 1rem;
}

.c-feature-heading {
  @include t5;
  font-weight: bold;
  margin-bottom: 24px;
  margin-top: 24px;
  color: $brand;
}
