.u-flex{
  display:flex
}

.u-inline-flex{
  display:inline-flex
}

.u-h1{
  font-family:Utopia, ui-sans-serif, system-ui, Arial;
  font-size:2.5rem;
  line-height:2.75rem;
  --tw-text-opacity:1;
  color:rgba(194, 7, 54, var(--tw-text-opacity))
}

@media (min-width: 768px){
  .u-h1{
    font-size:5rem;
    line-height:5rem
  }
}

.u-h1{
  color:#c20736
}

.u-h2{
  font-family:Utopia, ui-sans-serif, system-ui, Arial;
  font-size:1.5rem;
  line-height:1.875rem;
  --tw-text-opacity:1;
  color:rgba(194, 7, 54, var(--tw-text-opacity))
}

@media (min-width: 768px){
  .u-h2{
    font-size:1.875rem;
    line-height:2.25rem
  }
}

.u-h2{
  color:#c20736
}

@media screen and (max-width: 768px){
  .u-h2{
    font-family:Utopia, ui-sans-serif, system-ui, Arial;
    font-size:1.25rem;
    line-height:1.5rem;
    --tw-text-opacity:1;
    color:rgba(194, 7, 54, var(--tw-text-opacity))
  }

  @media (min-width: 768px){
    .u-h2{
      font-size:1.5rem;
      line-height:1.875rem
    }
  }
}

.c-choosable-feature{
  display:flex;
  margin-bottom:40px
}

.c-choosable-feature:nth-child(even) .c-choosable-feature__img{
  order:1
}

.c-choosable-feature:nth-child(even) .c-choosable-feature__text-container{
  order:2
}

.c-choosable-feature__text-container{
  flex:1;
  display:block;
  box-sizing:border-box;
  background-color:#e5e0da;
  position:relative;
  width:50%
}

@media screen and (max-width: 768px){
  .c-choosable-feature__text-container{
    width:100%
  }
}

.c-choosable-feature__text{
  padding:32px 32px 32px 64px;
  display:flex;
  flex-direction:column;
  height:100%;
  justify-content:space-between
}

.c-choosable-feature__text-top{
  margin-bottom:16px
}

.c-choosable-feature__number,.c-choosable-feature__size{
  display:inline-flex;
  align-items:center;
  justify-content:center
}

.c-choosable-feature__number{
  position:absolute;
  left:0;
  top:32px;
  height:48px;
  width:48px;
  background-color:#c20736;
  color:#fff
}

.c-choosable-feature__size{
  font-size:0.875rem;
  line-height:1.25rem;
  font-weight:700;
  padding:5px 8px 3px;
  background-color:#d4691b;
  margin-bottom:8px
}

.c-choosable-feature__size:not(:last-child){
  margin-right:8px
}

.c-choosable-feature__size--on-image{
  position:absolute;
  bottom:auto;
  top:32px
}

.c-choosable-feature__content:deep(p), .c-choosable-feature__content:deep(ul){
  margin-top:1.5rem;
  margin-bottom:1.5rem
}

.c-choosable-feature__content:deep(p),.c-choosable-feature__content:deep(ul){
  color:#2b2f43
}

.c-choosable-feature__content:deep(li){
  color:#2b2f43;
  font-size:16px;
  line-height:18px;
  padding-left:32px;
  position:relative
}

.c-choosable-feature__content:deep(li):not(:last-child){
  margin-bottom:8px
}

.c-choosable-feature__content:deep(li)::before{
  content:"";
  height:16px;
  width:16px;
  display:block;
  position:absolute;
  left:0;
  top:16px;
  transform:translateY(-50%);
  background-image:url("data:image/svg+xml,%0A%3Csvg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.3438 0.96875L16 2.625L5.59375 13.0312L0 7.4375L1.65625 5.78125L5.25 8.875L14.3438 0.96875Z' fill='%23aeae6e'/%3E%3C/svg%3E%0A");
  background-repeat:no-repeat
}

.c-choosable-feature__checkbox-label{
  cursor:pointer;
  position:relative;
  display:inline-flex;
  align-items:center;
  font-weight:700;
  color:#000
}

.c-choosable-feature__checkbox-icon{
  position:absolute;
  z-index:3;
  top:50%;
  transform:translateY(-50%);
  left:3px;
  margin-left:0;
  color:#fff
}

.c-choosable-feature__checkbox{
  cursor:pointer;
  -webkit-appearance:none;
  background-color:#fff;
  display:inline-block;
  border:1px solid #c3b6a9;
  padding:9px;
  margin-right:12px;
  width:30px;
  height:30px;
  min-width:30px
}

.c-choosable-feature__checkbox:focus{
  outline:none
}

.c-choosable-feature__checkbox:checked{
  background-color:#c20736;
  border-color:#c20736
}

@media screen and (max-width: 768px){
  .c-choosable-feature__checkbox{
    height:22px;
    width:22px
  }
}

.c-choosable-feature__img-container{
  flex:1;
  position:relative;
  display:block;
  width:50%
}

@media screen and (max-width: 768px){
  .c-choosable-feature__img-container{
    width:100%
  }
}

.c-choosable-feature__img{
  width:100%;
  height:100%;
  min-height:350px;
  -o-object-fit:cover;
     object-fit:cover;
  display:block
}

.c-choosable-feature__heading{
  margin-top:0.5rem;
  margin-bottom:0.5rem;
  font-family:Utopia, ui-sans-serif, system-ui, Arial;
  font-size:1.25rem;
  line-height:1.5rem;
  --tw-text-opacity:1;
  color:rgba(194, 7, 54, var(--tw-text-opacity))
}

@media (min-width: 768px){
  .c-choosable-feature__heading{
    font-size:1.5rem;
    line-height:1.875rem
  }
}

.c-choosable-feature__heading{
  color:#2b2f43
}

@media screen and (max-width: 768px){
  .c-choosable-feature{
    max-width:50%;
    margin-bottom:20px
  }

  .c-choosable-feature__img{
    order:1
  }

  .c-choosable-feature__text-container{
    order:2
  }

  .c-choosable-feature__img-container{
    flex:auto
  }

  .c-choosable-feature__checkbox{
    height:22px;
    width:22px;
    min-width:22px
  }
}

@media screen and (max-width: 768px){
  .c-choosable-feature{
    max-width:100%;
    margin-bottom:20px;
    flex-direction:column
  }
}