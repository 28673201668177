.c-link {
  transition: $default-transition;
  position: relative;
  text-decoration: none;

  &.c-link--underline {
    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 0;
      height: 2px;
      background-color: $brand;
      transition: $default-transition;
    }

    &:hover {
      &:after {
        width: 100%;
      }
    }
  }
}
