@import "@/assets/scss/abstracts/_global.scss";

.c-assortment {
  &__result {
    padding-bottom: 40px;

    &:not(:last-child) {
      margin-bottom: 80px;
    }

    &:last-child {
      margin-bottom: 50px;
    }
  }
}

.c-step__button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 32px;
}

.c-result {
  padding-bottom: 40px;
  margin-bottom: 50px;

  &__feature-description {
    color: #fff;
    margin-bottom: 40px;
  }

  &__product {
    margin-bottom: 56px;

    &-bundle {
      border: 4px solid $brand;
      padding: 40px;
      margin-bottom: 40px;
      position: relative;

      &::before {
        content: "Bundle";
        position: absolute;
        top: -15px;
        left: 40px;
        background-color: $brand;
        border-left: 4px solid $brand;
        border-right: 4px solid $brand;
        padding-left: 16px;
        padding-right: 16px;
        color: #fff;
        line-height: 28px;
      }

      &:lang(en) {
        &::before {
          content: "Bundle";
        }
      }

      &:lang(dk) {
        &::before {
          content: "Pakkeforslag";
        }
      }

      &:lang(fi) {
        &::before {
          content: "Pakettiehdotukset";
        }
      }

      &:lang(no) {
        &::before {
          content: "Pakkeforslag";
        }
      }

      &:lang(se) {
        &::before {
          content: "Paketförslag";
        }
      }
    }

    &-bundle {
      &__products {
        display: flex;
        align-items: center;
        align-items: stretch;

        @include mq($screen-m, max) {
          flex-direction: column;
        }

        .c-child-product {
          @include mq($screen-m, max) {
            max-width: 100%;
          }
        }
      }
    }

    &-bundle-plus {
      color: $brand;
      margin-left: 20px;
      margin-right: 20px;
      min-width: 40px;
      align-self: center;

      @include mq($screen-m, max) {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
  }
}

.c-alternatives {
  &__section {
    padding-bottom: 40px;

    &:not(:last-child) {
      margin-bottom: 80px;
    }

    &:last-child {
      margin-bottom: 50px;
    }
  }

  &__extras-heading {
    @include t5;
    color: #fff;
    margin-bottom: 24px;
  }

  &__main-feature {
    margin-bottom: 8px;
  }
}

.c-feature-heading {
  @include t5;
  font-weight: bold;
  margin-bottom: 24px;
  margin-top: 42px;
  margin-bottom: 36px;
  color: $black;
}
