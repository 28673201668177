@import "@/assets/scss/abstracts/_global.scss";

.c-child-product {
  background-color: $brand-warm-2;
  position: relative;
  max-width: calc(50% - 40px);

  &__energy-label {
    display: block;

    img {
      max-width: 80px;
      margin-left: -7px;
    }

    width: 100%;
  }

  &__addedToCartLink {
    margin-top: 16px;
  }

  &--full {
    display: flex;
    width: 100%;

    .c-child-product__size {
      top: 32px;
      bottom: auto;
    }

    .c-child-product__img-container,
    .c-child-product__info-container {
      flex: 1;
      width: 50%;
    }
  }

  &__img-container {
    position: relative;
    min-height: initial;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__size {
    @include s2;
    background-color: $brand-green-5;
    color: $snow;
    display: inline-block;
    padding: 9px 16px 7px;
    font-weight: 700;
    position: absolute;
    left: 0;
    bottom: 16px;
  }

  &__energy-label {
    max-width: 100px;
    display: block;
    margin-bottom: 10px;
  }

  &__info {
    padding: 40px;
  }

  &__title {
    @include t4;

    color: $black;
    margin-bottom: 16px;
  }

  &__description {
    margin-bottom: 16px;
    color: $black;

    &:deep(p),
    &:deep(ul) {
      margin-top: 16px;
      margin-bottom: 16px;
    }

    &:deep(li) {
      color: $black;
      font-size: 1rem;
      padding-left: 32px;
      position: relative;

      &:not(:last-child) {
        margin-bottom: 4px;
      }

      &::before {
        content: '';
        height: 16px;
        width: 16px;
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-image: url("data:image/svg+xml,%0A%3Csvg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.3438 0.96875L16 2.625L5.59375 13.0312L0 7.4375L1.65625 5.78125L5.25 8.875L14.3438 0.96875Z' fill='%23aeae6e'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
      }
    }
  }

  &__feature-text {
    @include t6;
    color: $black;
  }

  &__instructions {
    line-height: 20px;
  }

  .c-step__button-wrapper {
    margin-top: 30px;
  }

  @include mq($screen-xl, min) {
    &__size {
      bottom: 120px;
    }
  }

  @include mq($screen-m, max) {
    &--full {
      flex-direction: column;

      .c-child-product__size {
        bottom: 16px;
        top: auto;
      }
    }

    &__info {
      padding: 20px;
    }
  }

  @include mq($screen-m, min) {
    &--full {
      &:not(.c-child-product--img-right) {
        .c-child-product__size {
          right: 0;
          left: auto;
        }
      }
    }

    &--img-right {
      .c-child-product__img-container {
        order: 2;
      }
    }
  }
}
