.u-flex{
  display:flex
}

.u-inline-flex{
  display:inline-flex
}

.u-h1{
  font-family:Utopia, ui-sans-serif, system-ui, Arial;
  font-size:2.5rem;
  line-height:2.75rem;
  --tw-text-opacity:1;
  color:rgba(194, 7, 54, var(--tw-text-opacity))
}

@media (min-width: 768px){
  .u-h1{
    font-size:5rem;
    line-height:5rem
  }
}

.u-h1{
  color:#c20736
}

.u-h2{
  font-family:Utopia, ui-sans-serif, system-ui, Arial;
  font-size:1.5rem;
  line-height:1.875rem;
  --tw-text-opacity:1;
  color:rgba(194, 7, 54, var(--tw-text-opacity))
}

@media (min-width: 768px){
  .u-h2{
    font-size:1.875rem;
    line-height:2.25rem
  }
}

.u-h2{
  color:#c20736
}

@media screen and (max-width: 768px){
  .u-h2{
    font-family:Utopia, ui-sans-serif, system-ui, Arial;
    font-size:1.25rem;
    line-height:1.5rem;
    --tw-text-opacity:1;
    color:rgba(194, 7, 54, var(--tw-text-opacity))
  }

  @media (min-width: 768px){
    .u-h2{
      font-size:1.5rem;
      line-height:1.875rem
    }
  }
}

.c-assortment__result{
  padding-bottom:40px
}

.c-assortment__result:not(:last-child){
  margin-bottom:80px
}

.c-assortment__result:last-child{
  margin-bottom:50px
}

.c-step__button-wrapper{
  display:flex;
  justify-content:center;
  margin-top:32px;
  margin-bottom:32px
}

.c-result{
  padding-bottom:40px;
  margin-bottom:50px
}

.c-result__feature-description{
  color:#fff;
  margin-bottom:40px
}

.c-result__product{
  margin-bottom:56px
}

.c-result__product-bundle{
  border:4px solid #c20736;
  padding:40px;
  margin-bottom:40px;
  position:relative
}

.c-result__product-bundle::before{
  content:"Bundle";
  position:absolute;
  top:-15px;
  left:40px;
  background-color:#c20736;
  border-left:4px solid #c20736;
  border-right:4px solid #c20736;
  padding-left:16px;
  padding-right:16px;
  color:#fff;
  line-height:28px
}

.c-result__product-bundle:lang(en)::before{
  content:"Bundle"
}

.c-result__product-bundle:lang(dk)::before{
  content:"Pakkeforslag"
}

.c-result__product-bundle:lang(fi)::before{
  content:"Pakettiehdotukset"
}

.c-result__product-bundle:lang(no)::before{
  content:"Pakkeforslag"
}

.c-result__product-bundle:lang(se)::before{
  content:"Paketförslag"
}

.c-result__product-bundle__products{
  display:flex;
  align-items:center;
  align-items:stretch
}

@media screen and (max-width: 768px){
  .c-result__product-bundle__products{
    flex-direction:column
  }
}

@media screen and (max-width: 768px){
  .c-result__product-bundle__products .c-child-product{
    max-width:100%
  }
}

.c-result__product-bundle-plus{
  color:#c20736;
  margin-left:20px;
  margin-right:20px;
  min-width:40px;
  align-self:center
}

@media screen and (max-width: 768px){
  .c-result__product-bundle-plus{
    margin-top:20px;
    margin-bottom:20px
  }
}

.c-alternatives__section{
  padding-bottom:40px
}

.c-alternatives__section:not(:last-child){
  margin-bottom:80px
}

.c-alternatives__section:last-child{
  margin-bottom:50px
}

.c-alternatives__extras-heading{
  font-family:Utopia, ui-sans-serif, system-ui, Arial;
  font-size:1.25rem;
  line-height:1.5rem;
  --tw-text-opacity:1;
  color:rgba(194, 7, 54, var(--tw-text-opacity))
}

@media (min-width: 768px){
  .c-alternatives__extras-heading{
    font-size:1.5rem;
    line-height:1.875rem
  }
}

.c-alternatives__extras-heading{
  color:#fff;
  margin-bottom:24px
}

.c-alternatives__main-feature{
  margin-bottom:8px
}

.c-feature-heading{
  font-family:Utopia, ui-sans-serif, system-ui, Arial;
  font-size:1.25rem;
  line-height:1.5rem;
  --tw-text-opacity:1;
  color:rgba(194, 7, 54, var(--tw-text-opacity))
}

@media (min-width: 768px){
  .c-feature-heading{
    font-size:1.5rem;
    line-height:1.875rem
  }
}

.c-feature-heading{
  font-weight:bold;
  margin-bottom:24px;
  margin-top:42px;
  margin-bottom:36px;
  color:#000
}