@import "@/assets/scss/abstracts/_global.scss";

.c-choosable-feature {
  display: flex;
  margin-bottom: 40px;

  &:nth-child(even) {
    .c-choosable-feature__img {
      order: 1;
    }

    .c-choosable-feature__text-container {
      order: 2;
    }
  }

  &__text-container {
    flex: 1;
    display: block;
    box-sizing: border-box;
    background-color: $brand-warm-2;
    position: relative;
    width: 50%;

    @include mq($screen-m, max) {
      width: 100%;
    }
  }

  &__text {
    padding: 32px 32px 32px 64px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    &-top {
      margin-bottom: 16px;
    }
  }

  &__number,
  &__size {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &__number {
    position: absolute;
    left: 0;
    top: 32px;
    height: 48px;
    width: 48px;
    background-color: $brand;
    color: #fff;
  }

  &__size {
    @include s2;
    font-weight: 700;
    padding: 5px 8px 3px;
    background-color: $orange;

    &:not(:last-child) {
      margin-right: 8px;
    }

    margin-bottom: 8px;

    &--on-image {
      position: absolute;
      bottom: auto;
      top: 32px;
    }
  }

  &__content {

    &:deep(p),
    &:deep(ul) {
      @apply my-6;
      color: $deep-blue-2;
    }

    &:deep(li) {
      color: $deep-blue-2;
      font-size: 16px;
      line-height: 18px;
      padding-left: 32px;
      position: relative;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      &::before {
        content: '';
        height: 16px;
        width: 16px;
        display: block;
        position: absolute;
        left: 0;
        top: 16px;
        transform: translateY(-50%);
        background-image: url("data:image/svg+xml,%0A%3Csvg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.3438 0.96875L16 2.625L5.59375 13.0312L0 7.4375L1.65625 5.78125L5.25 8.875L14.3438 0.96875Z' fill='%23aeae6e'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
      }
    }
  }

  &__checkbox-label {
    cursor: pointer;
    position: relative;
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    color: $black;
  }

  &__checkbox-icon {
    position: absolute;
    z-index: 3;
    top: 50%;
    transform: translateY(-50%);
    left: 3px;
    margin-left: 0;
    color: #fff;
  }

  &__checkbox {
    cursor: pointer;
    -webkit-appearance: none;
    background-color: #fff;
    display: inline-block;
    border: 1px solid $brand-warm-4;
    padding: 9px;
    margin-right: 12px;
    width: 30px;
    height: 30px;
    min-width: 30px;

    &:focus {
      outline: none;
    }

    &:checked {
      background-color: $brand;
      border-color: $brand;
    }

    @include mq($screen-m, max) {
      height: 22px;
      width: 22px;
    }
  }

  &__img-container {
    flex: 1;
    position: relative;
    display: block;
    width: 50%;

    @include mq($screen-m, max) {
      width: 100%;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    min-height: 350px;
    object-fit: cover;
    display: block;
  }

  &__heading {
    @apply my-2;
    @include t5;
    color: $deep-blue-2;
  }

  @include mq($screen-m, max) {
    max-width: 50%;
    margin-bottom: 20px;

    &__img {
      order: 1;
    }

    &__text-container {
      order: 2;
    }

    &__img-container {
      flex: auto;
    }

    &__checkbox {
      height: 22px;
      width: 22px;
      min-width: 22px;
    }
  }

  @include mq($screen-m, max) {
    max-width: 100%;
    margin-bottom: 20px;
    flex-direction: column;
  }
}
