@import "@/assets/scss/abstracts/_global.scss";

.c-language-switcher {
  position: relative;

  .c-language-switcher__button {
    @apply text-black bg-brand-warm-1 lg:bg-transparent px-3 py-1 lg:px-0;

    position: relative;
    transition: $default-transition;

    &:hover,
    &.is-open {
      color: $brand;
    }
  }

  .c-language-switcher__languages {
    @apply border border-solid border-brand-warm-2;

    position: absolute;
    right: 0;
    z-index: 20;
    width: 150px;
    background-color: $brand-warm-1;
  }

  .c-language-switcher__language {
    @apply border-b border-solid border-brand-warm-2;

    transition: $default-transition;
    display: block;
    padding: 10px;
    font-weight: normal;
    text-decoration: none;
    color: $black;

    &:hover {
      color: $brand;
      cursor: pointer;
    }
  }

  li:last-child {
    .c-language-switcher__language {
      @apply border-none;
    }
  }
}
