.u-flex{
  display:flex
}

.u-inline-flex{
  display:inline-flex
}

.u-h1{
  font-family:Utopia, ui-sans-serif, system-ui, Arial;
  font-size:2.5rem;
  line-height:2.75rem;
  --tw-text-opacity:1;
  color:rgba(194, 7, 54, var(--tw-text-opacity))
}

@media (min-width: 768px){
  .u-h1{
    font-size:5rem;
    line-height:5rem
  }
}

.u-h1{
  color:#c20736
}

.u-h2{
  font-family:Utopia, ui-sans-serif, system-ui, Arial;
  font-size:1.5rem;
  line-height:1.875rem;
  --tw-text-opacity:1;
  color:rgba(194, 7, 54, var(--tw-text-opacity))
}

@media (min-width: 768px){
  .u-h2{
    font-size:1.875rem;
    line-height:2.25rem
  }
}

.u-h2{
  color:#c20736
}

@media screen and (max-width: 768px){
  .u-h2{
    font-family:Utopia, ui-sans-serif, system-ui, Arial;
    font-size:1.25rem;
    line-height:1.5rem;
    --tw-text-opacity:1;
    color:rgba(194, 7, 54, var(--tw-text-opacity))
  }

  @media (min-width: 768px){
    .u-h2{
      font-size:1.5rem;
      line-height:1.875rem
    }
  }
}

.modal-mask{
  position:fixed;
  z-index:9998;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color:rgba(0,0,0,.5);
  display:table;
  transition:opacity .3s ease
}

.modal-wrapper{
  display:table-cell;
  vertical-align:middle
}

.modal-container{
  color:#000;
  width:400px;
  margin:0px auto;
  padding:20px 30px;
  background-color:#fff;
  border-radius:2px;
  box-shadow:0 2px 8px rgba(0,0,0,.33);
  transition:all .3s ease
}

.modal-errors{
  color:#e0173b
}

.modal-header h3{
  margin-top:0;
  color:#42b983
}

.modal-body{
  margin-top:1.25rem;
  margin-bottom:1.25rem
}

.modal-default-button{
  display:block;
  margin-top:1rem
}

.c-reminder__email-input{
  width:100%;
  padding-bottom:0.5rem;
  transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration:150ms;
  min-width:250px;
  border:none;
  border-bottom:2px solid #000;
  display:block
}

.c-reminder__email-input:focus{
  outline:none;
  border-color:#c20736
}

.modal-container{
  position:relative
}

.modal__close{
  background:none;
  position:absolute;
  top:5px;
  right:24px;
  padding:5px;
  text-align:center;
  vertical-align:center;
  transition:all .3s;
  font-size:24px;
  color:#000
}

.modal__close:hover{
  color:#000
}

.modal-enter{
  opacity:0
}

.modal-leave-active{
  opacity:0
}

.modal-enter .modal-container,.modal-leave-active .modal-container{
  transform:scale(1.1)
}