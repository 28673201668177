@font-face {
  font-family: "Neff Sans";
  src: local("Neff Sans"),
       url(~@/assets/fonts/NeffSans-Regular.woff) format("woff"),
       url(~@/assets/fonts/NeffSans-Regular.woff2) format("woff2");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Neff Sans";
  src: local("Neff Sans"),
       url(~@/assets/fonts/NeffSans-Bold.woff) format("woff"),
       url(~@/assets/fonts/NeffSans-Bold.woff2) format("woff2");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Utopia";
  src: local("Neff Sans"),
       url(~@/assets/fonts/UtopiaW05-SemiboldCaption.woff) format("woff"),
       url(~@/assets/fonts/UtopiaW05-SemiboldCaption.woff2) format("woff2");
  font-style: normal;
  font-weight: 400
}
