.u-flex{
  display:flex
}

.u-inline-flex{
  display:inline-flex
}

.u-h1{
  font-family:Utopia, ui-sans-serif, system-ui, Arial;
  font-size:2.5rem;
  line-height:2.75rem;
  --tw-text-opacity:1;
  color:rgba(194, 7, 54, var(--tw-text-opacity))
}

@media (min-width: 768px){
  .u-h1{
    font-size:5rem;
    line-height:5rem
  }
}

.u-h1{
  color:#c20736
}

.u-h2{
  font-family:Utopia, ui-sans-serif, system-ui, Arial;
  font-size:1.5rem;
  line-height:1.875rem;
  --tw-text-opacity:1;
  color:rgba(194, 7, 54, var(--tw-text-opacity))
}

@media (min-width: 768px){
  .u-h2{
    font-size:1.875rem;
    line-height:2.25rem
  }
}

.u-h2{
  color:#c20736
}

@media screen and (max-width: 768px){
  .u-h2{
    font-family:Utopia, ui-sans-serif, system-ui, Arial;
    font-size:1.25rem;
    line-height:1.5rem;
    --tw-text-opacity:1;
    color:rgba(194, 7, 54, var(--tw-text-opacity))
  }

  @media (min-width: 768px){
    .u-h2{
      font-size:1.5rem;
      line-height:1.875rem
    }
  }
}

.top-border{
  border-style:solid;
  --tw-border-opacity:1;
  border-color:rgba(194, 7, 54, var(--tw-border-opacity));
  border-top-width:12px
}

@media screen and (max-width: 768px){
  .top-border{
    border-top-width:8px
  }
}

@media screen and (max-width: 640px){
  .top-border{
    border-top-width:5px
  }
}