.u-flex{
  display:flex
}

.u-inline-flex{
  display:inline-flex
}

.u-h1{
  font-family:Utopia, ui-sans-serif, system-ui, Arial;
  font-size:2.5rem;
  line-height:2.75rem;
  --tw-text-opacity:1;
  color:rgba(194, 7, 54, var(--tw-text-opacity))
}

@media (min-width: 768px){
  .u-h1{
    font-size:5rem;
    line-height:5rem
  }
}

.u-h1{
  color:#c20736
}

.u-h2{
  font-family:Utopia, ui-sans-serif, system-ui, Arial;
  font-size:1.5rem;
  line-height:1.875rem;
  --tw-text-opacity:1;
  color:rgba(194, 7, 54, var(--tw-text-opacity))
}

@media (min-width: 768px){
  .u-h2{
    font-size:1.875rem;
    line-height:2.25rem
  }
}

.u-h2{
  color:#c20736
}

@media screen and (max-width: 768px){
  .u-h2{
    font-family:Utopia, ui-sans-serif, system-ui, Arial;
    font-size:1.25rem;
    line-height:1.5rem;
    --tw-text-opacity:1;
    color:rgba(194, 7, 54, var(--tw-text-opacity))
  }

  @media (min-width: 768px){
    .u-h2{
      font-size:1.5rem;
      line-height:1.875rem
    }
  }
}

.c-header{
  display:flex;
  justify-content:space-between;
  border-style:solid;
  --tw-border-opacity:1;
  border-color:rgba(194, 7, 54, var(--tw-border-opacity));
  padding-top:2rem;
  padding-bottom:2rem;
  border-top-width:12px
}

@media screen and (max-width: 768px){
  .c-header{
    padding-top:1.5rem;
    padding-bottom:1.5rem;
    border-top-width:8px
  }
}

@media screen and (max-width: 640px){
  .c-header{
    border-top-width:5px
  }
}

.c-header__right{
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  align-items:flex-end
}

@media screen and (max-width: 640px){
  .c-header__right{
    justify-content:center
  }
}

.c-header__language-button{
  font-size:0.875rem;
  line-height:1.25rem;
  color:#f2efed;
  background-color:transparent;
  position:relative;
  transition:all .15s ease-in-out
}

.c-header__language-button:hover,.c-header__language-button.is-open{
  color:#c20736
}

.c-header__language-switcher{
  position:relative
}

.c-header__languages{
  position:absolute;
  right:0;
  z-index:2;
  margin-top:10px;
  width:150px;
  background-color:#c20736
}

.c-header__language{
  transition:all .15s ease-in-out;
  display:block;
  padding:10px;
  border-bottom:1px solid #f2efed;
  font-size:14px;
  font-weight:normal;
  text-decoration:none;
  color:#000
}

.c-header__language:hover{
  color:#c20736
}

.c-header__inner{
  display:flex;
  justify-content:space-between
}

@media print{
  .no-print,.no-print *{
    display:none !important
  }
}