@import "@/assets/scss/abstracts/_global.scss";

.c-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 8px 24px 8px 24px;
  border: 1px solid $brand;
  text-align: center;
  transition: $default-transition;
  cursor: pointer;
  min-width: 230px;
  font-weight: 700;

  &:first-letter {
    text-transform: uppercase;
  }

  span:first-letter {
    text-transform: uppercase;
  }

  &:disabled {
    cursor: not-allowed;
    background: $brand-warm-3;
    color: #fff;
    border-color: $brand-warm-3;
  }

  &.c-btn--primary {
    background: $brand;
    color: $snow;

    &:hover {
      filter: sepia(25%);
    }

    &:disabled {
      cursor: not-allowed;
      background: $brand-warm-3;
      color: #fff;
      border-color: $brand-warm-3;

      &:hover {
        background: $brand-warm-3;
        color: #fff;
        border-color: $brand-warm-3;
      }
    }
  }

  &.c-btn--secondary {
    background: transparent;
    color: $brand;
    border-color: $brand;

    &:hover {
      color: $brand;
      border-color: $brand;
      outline: 1px solid $brand;
    }

    &:disabled {
      background: transparent;
      cursor: not-allowed;
      color: $brand;
      border-color: $brand;
      opacity: 0.4;

      &:hover {
        color: $brand;
        border-color: $brand;
        outline: none;
      }
    }
  }

  &.c-btn--dark {
    @apply border-black text-black;
  }
}
