.u-flex{
  display:flex
}

.u-inline-flex{
  display:inline-flex
}

.u-h1{
  font-family:Utopia, ui-sans-serif, system-ui, Arial;
  font-size:2.5rem;
  line-height:2.75rem;
  --tw-text-opacity:1;
  color:rgba(194, 7, 54, var(--tw-text-opacity))
}

@media (min-width: 768px){
  .u-h1{
    font-size:5rem;
    line-height:5rem
  }
}

.u-h1{
  color:#c20736
}

.u-h2{
  font-family:Utopia, ui-sans-serif, system-ui, Arial;
  font-size:1.5rem;
  line-height:1.875rem;
  --tw-text-opacity:1;
  color:rgba(194, 7, 54, var(--tw-text-opacity))
}

@media (min-width: 768px){
  .u-h2{
    font-size:1.875rem;
    line-height:2.25rem
  }
}

.u-h2{
  color:#c20736
}

@media screen and (max-width: 768px){
  .u-h2{
    font-family:Utopia, ui-sans-serif, system-ui, Arial;
    font-size:1.25rem;
    line-height:1.5rem;
    --tw-text-opacity:1;
    color:rgba(194, 7, 54, var(--tw-text-opacity))
  }

  @media (min-width: 768px){
    .u-h2{
      font-size:1.5rem;
      line-height:1.875rem
    }
  }
}

.c-basic-feature{
  --tw-bg-opacity:1;
  background-color:rgba(229, 224, 218, var(--tw-bg-opacity));
  display:flex;
  flex-direction:column;
  flex:1;
  overflow:hidden
}

.c-basic-feature__text-container{
  height:100%;
  padding:20px;
  color:#000
}

.c-basic-feature__heading{
  font-family:Utopia, ui-sans-serif, system-ui, Arial;
  font-size:1.125rem;
  line-height:1.75rem;
  --tw-text-opacity:1;
  color:rgba(194, 7, 54, var(--tw-text-opacity))
}

@media (min-width: 768px){
  .c-basic-feature__heading{
    font-size:1.25rem;
    line-height:1.5rem
  }
}

.c-basic-feature__heading{
  font-size:1.675rem;
  line-height:2.1rem;
  margin-bottom:.5rem
}

.c-basic-feature__img-container{
  display:flex
}

.c-basic-feature__img-container .imageslider img{
  height:auto
}

.c-basic-feature__img{
  width:100%;
  height:100%;
  -o-object-fit:cover;
     object-fit:cover
}

@media screen and (max-width: 768px){
  .c-basic-feature{
    margin-bottom:20px;
    min-width:45%;
    width:100%
  }
}

@media screen and (max-width: 640px){
  .c-basic-feature{
    margin-bottom:20px;
    min-width:100%;
    width:100%
  }
}