@import "@/assets/scss/abstracts/_global.scss";

.c-header {
  @apply flex justify-between py-8 border-solid border-brand-primary;

  border-top-width: 12px;

  @include mq($screen-m, max) {
    @apply py-6;

    border-top-width: 8px;
  }

  @include mq($screen-s, max) {
    border-top-width: 5px;
  }

  &__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    @include mq($screen-s, max) {
      justify-content: center;
    }
  }

  &__language-button {
    @include s2;
    color: $brand-warm-1;
    background-color: transparent;
    position: relative;
    transition: $default-transition;

    &:hover,
    &.is-open {
      color: $brand;
    }
  }

  &__language-switcher {
    position: relative;
  }

  &__languages {
    position: absolute;
    right: 0;
    z-index: 2;
    margin-top: 10px;
    width: 150px;
    background-color: $brand;
  }

  &__language {
    transition: $default-transition;
    display: block;
    padding: 10px;
    border-bottom: 1px solid $brand-warm-1;
    font-size: 14px;
    font-weight: normal;
    text-decoration: none;
    color: $black;

    &:hover {
      color: $brand;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
  }
}

@media print {

  .no-print,
  .no-print * {
    display: none !important;
  }
}
