@import "@/assets/scss/abstracts/_global.scss";

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  color: #000;
  width: 400px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-errors {
  color: #E0173B;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  @apply my-5;
}

.modal-default-button {
  display: block;
  margin-top: 1rem;
}

.c-reminder__email-input {
  @apply transition pb-2 w-full;

  min-width: 250px;
  border: none;
  border-bottom: 2px solid #000;
  display: block;

  &:focus {
    outline: none;
    border-color: $brand;
  }
}

.modal {
  &-container {
    position: relative;
  }

  &__close {
    background: none;
    position: absolute;
    top: 5px;
    right: 24px;
    padding: 5px;
    text-align: center;
    vertical-align: center;
    transition: all 0.3s;
    font-size: 24px;
    color: #000;

    &:hover {
      color: #000;
    }
  }
}

/*
* The following styles are auto-applied to elements with
* transition="modal" when their visibility is toggled
* by Vue.js.
*
* You can easily play with the modal transition by editing
* these styles.
*/

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  transform: scale(1.1);
}
