@import "@/assets/scss/abstracts/_global.scss";

.c-parent-cat__heading1 {
  @apply mb-10;
  @include t2;
}

.c-parent-cat__description {
  @apply mb-10;
}
