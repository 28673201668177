.u-flex{
  display:flex
}

.u-inline-flex{
  display:inline-flex
}

.u-h1{
  font-family:Utopia, ui-sans-serif, system-ui, Arial;
  font-size:2.5rem;
  line-height:2.75rem;
  --tw-text-opacity:1;
  color:rgba(194, 7, 54, var(--tw-text-opacity))
}

@media (min-width: 768px){
  .u-h1{
    font-size:5rem;
    line-height:5rem
  }
}

.u-h1{
  color:#c20736
}

.u-h2{
  font-family:Utopia, ui-sans-serif, system-ui, Arial;
  font-size:1.5rem;
  line-height:1.875rem;
  --tw-text-opacity:1;
  color:rgba(194, 7, 54, var(--tw-text-opacity))
}

@media (min-width: 768px){
  .u-h2{
    font-size:1.875rem;
    line-height:2.25rem
  }
}

.u-h2{
  color:#c20736
}

@media screen and (max-width: 768px){
  .u-h2{
    font-family:Utopia, ui-sans-serif, system-ui, Arial;
    font-size:1.25rem;
    line-height:1.5rem;
    --tw-text-opacity:1;
    color:rgba(194, 7, 54, var(--tw-text-opacity))
  }

  @media (min-width: 768px){
    .u-h2{
      font-size:1.5rem;
      line-height:1.875rem
    }
  }
}

.c-icon{
  display:inline-block;
  width:16px;
  height:16px;
  stroke-width:0;
  stroke:currentColor;
  fill:currentColor
}

.c-icon--small{
  width:12px;
  height:12px
}

.c-icon--large{
  width:24px;
  height:24px
}

@media screen and (max-width: 768px){
  .c-icon--large{
    width:16px;
    height:16px
  }
}

.c-icon--xl{
  width:40px;
  height:40px
}

.c-icon--xxl{
  width:96px;
  height:96px
}

.c-icon--before{
  margin-right:8px
}

.c-icon--after{
  margin-left:8px
}