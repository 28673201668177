.u-flex{
  display:flex
}

.u-inline-flex{
  display:inline-flex
}

.u-h1{
  font-family:Utopia, ui-sans-serif, system-ui, Arial;
  font-size:2.5rem;
  line-height:2.75rem;
  --tw-text-opacity:1;
  color:rgba(194, 7, 54, var(--tw-text-opacity))
}

@media (min-width: 768px){
  .u-h1{
    font-size:5rem;
    line-height:5rem
  }
}

.u-h1{
  color:#c20736
}

.u-h2{
  font-family:Utopia, ui-sans-serif, system-ui, Arial;
  font-size:1.5rem;
  line-height:1.875rem;
  --tw-text-opacity:1;
  color:rgba(194, 7, 54, var(--tw-text-opacity))
}

@media (min-width: 768px){
  .u-h2{
    font-size:1.875rem;
    line-height:2.25rem
  }
}

.u-h2{
  color:#c20736
}

@media screen and (max-width: 768px){
  .u-h2{
    font-family:Utopia, ui-sans-serif, system-ui, Arial;
    font-size:1.25rem;
    line-height:1.5rem;
    --tw-text-opacity:1;
    color:rgba(194, 7, 54, var(--tw-text-opacity))
  }

  @media (min-width: 768px){
    .u-h2{
      font-size:1.5rem;
      line-height:1.875rem
    }
  }
}

.c-language-switcher{
  position:relative
}

.c-language-switcher .c-language-switcher__button{
  --tw-bg-opacity:1;
  background-color:rgba(242, 239, 237, var(--tw-bg-opacity));
  padding-left:0.75rem;
  padding-right:0.75rem;
  padding-top:0.25rem;
  padding-bottom:0.25rem;
  --tw-text-opacity:1;
  color:rgba(0, 0, 0, var(--tw-text-opacity))
}

@media (min-width: 1024px){
  .c-language-switcher .c-language-switcher__button{
    background-color:transparent;
    padding-left:0px;
    padding-right:0px
  }
}

.c-language-switcher .c-language-switcher__button{
  position:relative;
  transition:all .15s ease-in-out
}

.c-language-switcher .c-language-switcher__button:hover,.c-language-switcher .c-language-switcher__button.is-open{
  color:#c20736
}

.c-language-switcher .c-language-switcher__languages{
  border-width:1px;
  border-style:solid;
  --tw-border-opacity:1;
  border-color:rgba(229, 224, 218, var(--tw-border-opacity));
  position:absolute;
  right:0;
  z-index:20;
  width:150px;
  background-color:#f2efed
}

.c-language-switcher .c-language-switcher__language{
  border-bottom-width:1px;
  border-style:solid;
  --tw-border-opacity:1;
  border-color:rgba(229, 224, 218, var(--tw-border-opacity));
  transition:all .15s ease-in-out;
  display:block;
  padding:10px;
  font-weight:normal;
  text-decoration:none;
  color:#000
}

.c-language-switcher .c-language-switcher__language:hover{
  color:#c20736;
  cursor:pointer
}

.c-language-switcher li:last-child .c-language-switcher__language{
  border-style:none
}