@import "@/assets/scss/abstracts/_global.scss";

.top-border {
  @apply border-solid border-brand-primary;

  border-top-width: 12px;

  @include mq($screen-m, max) {
    border-top-width: 8px;
  }

  @include mq($screen-s, max) {
    border-top-width: 5px;
  }
}
