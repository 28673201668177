//Media query
@mixin mq($point, $query1: min, $query2: width) {
  @media screen and (#{$query1}-#{$query2}: $point) {
    @content;
  }
}

//Typography
@mixin t1 {
  @apply text-4xl md:text-7xl font-heading text-brand-primary;
}

@mixin t2 {
  @apply text-3.5xl md:text-5xl font-heading text-brand-primary;
}

@mixin t3 {
  @apply text-2.5xl md:text-4xl font-heading text-brand-primary;
}

@mixin t4 {
  @apply text-2xl md:text-3xl font-heading text-brand-primary;
}

@mixin t5 {
  @apply text-xl md:text-2xl font-heading text-brand-primary;
}

@mixin t6 {
  @apply text-lg md:text-xl font-heading text-brand-primary;
}

@mixin s1 {
  @apply text-xs;
}

@mixin s2 {
  @apply text-sm;
}
