.u-flex{
  display:flex
}

.u-inline-flex{
  display:inline-flex
}

.u-h1{
  font-family:Utopia, ui-sans-serif, system-ui, Arial;
  font-size:2.5rem;
  line-height:2.75rem;
  --tw-text-opacity:1;
  color:rgba(194, 7, 54, var(--tw-text-opacity))
}

@media (min-width: 768px){
  .u-h1{
    font-size:5rem;
    line-height:5rem
  }
}

.u-h1{
  color:#c20736
}

.u-h2{
  font-family:Utopia, ui-sans-serif, system-ui, Arial;
  font-size:1.5rem;
  line-height:1.875rem;
  --tw-text-opacity:1;
  color:rgba(194, 7, 54, var(--tw-text-opacity))
}

@media (min-width: 768px){
  .u-h2{
    font-size:1.875rem;
    line-height:2.25rem
  }
}

.u-h2{
  color:#c20736
}

@media screen and (max-width: 768px){
  .u-h2{
    font-family:Utopia, ui-sans-serif, system-ui, Arial;
    font-size:1.25rem;
    line-height:1.5rem;
    --tw-text-opacity:1;
    color:rgba(194, 7, 54, var(--tw-text-opacity))
  }

  @media (min-width: 768px){
    .u-h2{
      font-size:1.5rem;
      line-height:1.875rem
    }
  }
}

.c-btn{
  display:inline-flex;
  justify-content:center;
  align-items:center;
  padding:8px 24px 8px 24px;
  border:1px solid #c20736;
  text-align:center;
  transition:all .15s ease-in-out;
  cursor:pointer;
  min-width:230px;
  font-weight:700
}

.c-btn:first-letter{
  text-transform:uppercase
}

.c-btn span:first-letter{
  text-transform:uppercase
}

.c-btn:disabled{
  cursor:not-allowed;
  background:#d4cbc2;
  color:#fff;
  border-color:#d4cbc2
}

.c-btn.c-btn--primary{
  background:#c20736;
  color:#fff
}

.c-btn.c-btn--primary:hover{
  filter:sepia(25%)
}

.c-btn.c-btn--primary:disabled{
  cursor:not-allowed;
  background:#d4cbc2;
  color:#fff;
  border-color:#d4cbc2
}

.c-btn.c-btn--primary:disabled:hover{
  background:#d4cbc2;
  color:#fff;
  border-color:#d4cbc2
}

.c-btn.c-btn--secondary{
  background:transparent;
  color:#c20736;
  border-color:#c20736
}

.c-btn.c-btn--secondary:hover{
  color:#c20736;
  border-color:#c20736;
  outline:1px solid #c20736
}

.c-btn.c-btn--secondary:disabled{
  background:transparent;
  cursor:not-allowed;
  color:#c20736;
  border-color:#c20736;
  opacity:.4
}

.c-btn.c-btn--secondary:disabled:hover{
  color:#c20736;
  border-color:#c20736;
  outline:none
}

.c-btn.c-btn--dark{
  --tw-border-opacity:1;
  border-color:rgba(0, 0, 0, var(--tw-border-opacity));
  --tw-text-opacity:1;
  color:rgba(0, 0, 0, var(--tw-text-opacity))
}